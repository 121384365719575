const templates = {
  secretSanta: [
    {
      title: 'Draw Announcement',
      description: 'Hello {Participant A}!\n\nYou have been chosen to surprise {Participant B}.\n\nRemember, keep it a secret until the {date}!'
    },
    {
      title: 'Drawing Results',
      description: 'Hello {Participant A}!\n\nYour designated person is {Participant B}.\n\nMake sure to surprise them on {date}.'
    },
    {
      title: 'Gift Exchange Draw',
      description: 'Hello {Participant A}!\n\nYou\'re tasked with gifting {Participant B} on {date}.\n\nLet the excitement begin!'
    }
]
}

export default templates;