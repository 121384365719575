import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material'

const ResultsTable = ({ pairs, showPairs, groupResults, handleClickOpen }) => {
  return (
    <>
      {groupResults.map(([group, pairs], index) => (
        <div key={index}>
          <h3>{group}</h3>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Regala:</TableCell>
                  <TableCell>Recive:</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pairs.map((pair, index) => (
                  <TableRow key={index}>
                    <TableCell>{pair[1].name}</TableCell>
                    <TableCell>{showPairs ? pair[2].name : '***'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ))}
    </>
  )
}

export default ResultsTable
