import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Container } from '@mui/material'

import { createTheme, ThemeProvider } from '@mui/material/styles'

import Pair from './pages/Pair'
import Home from './pages/Home'
import Shuffle from './pages/Shuffle'

const theme = createTheme({
  palette: {
    mode: 'light'
  },
  spacing: 4
})

function App () {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth='xl'>
        <Router>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/match/:id' element={<Pair />} />
            <Route path='/suffle/:id' element={<Shuffle />} />
          </Routes>
        </Router>
      </Container>
    </ThemeProvider>
  )
}

export default App
