import React from 'react'
import { Box, Typography } from '@mui/material'

const Footer = () => {
  return (
    <Box
      sx={{ py: 3, px: 2, mt: 'auto', backgroundColor: 'background.default' }}
      style={{ margin: '60px 0' }}
    >
      <Typography variant='body2' color='text.secondary' align='center'>
        © {new Date().getFullYear()} Shuffle Match
      </Typography>
    </Box>
  )
}

export default Footer
