import React, { useState, useEffect, useCallback } from 'react'
import {
  TextField,
  Button,
  TableContainer,
  Grid,
  Autocomplete,
  Tooltip,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel
} from '@mui/material'
import { ExpandMore as ExpandIcon } from '@mui/icons-material'
import { v4 as uuidv4 } from 'uuid'
import templates from '../datos/templates'
import placeholderNames from '../datos/names'

import ParticipantsTable from '../components/ParticipantsTable'
import Footer from '../components/Footer'
import ResultsTable from '../components/ResultsTable'

function Home () {
  const nameInputRef = React.useRef(null)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [group, setGroup] = useState('')
  const [expanded, setExpanded] = useState('panel1')
  const [people, setPeople] = useState(() => {
    const savedPeople = localStorage.getItem('people')
    return savedPeople ? JSON.parse(savedPeople) : []
  })
  const [pairs, setPairs] = useState(
    JSON.parse(localStorage.getItem('pairs')) || []
  )
  const [showPairs, setShowPairs] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [adminEmail, setAdminEmail] = useState(
    localStorage.getItem('adminEmail') || ''
  )
  const steps = ['Introduccion y mensajes', 'Agregar participantes']

  const randomTemplateIndex = Math.floor(
    Math.random() * templates.secretSanta.length
  )
  const randomIndex1 = Math.floor(Math.random() * placeholderNames.length)
  let randomIndex2 = randomIndex1
  while (randomIndex1 === randomIndex2) {
    randomIndex2 = Math.floor(Math.random() * placeholderNames.length)
  }
  const randomTemplate = templates.secretSanta[randomTemplateIndex]

  const [title, setTitle] = useState(
    localStorage.getItem('title') || randomTemplate.title
  )
  const [description, setDescription] = useState(
    localStorage.getItem('description') || randomTemplate.description
  )

  const [adminReminder, setAdminReminder] = useState(
    JSON.parse(localStorage.getItem('adminReminder')) || false
  )
  const [participantReminder, setParticipantReminder] = useState(
    JSON.parse(localStorage.getItem('participantReminder')) || false
  )

  useEffect(() => {
    localStorage.setItem('adminReminder', JSON.stringify(adminReminder))
  }, [adminReminder])

  useEffect(() => {
    localStorage.setItem(
      'participantReminder',
      JSON.stringify(participantReminder)
    )
  }, [participantReminder])

  const [dateTime, setDateTime] = useState(
    localStorage.getItem('dateTime') || ''
  )

  const handleDateTimeChange = event => {
    const newDateTime = event.target.value
    const now = new Date()
    const selectedDateTime = new Date(newDateTime)

    if (selectedDateTime >= now) {
      setDateTime(newDateTime)
    } else {
      alert('Please select a date and time in the future.')
    }
  }
  useEffect(() => {
    localStorage.setItem('dateTime', dateTime)
  }, [dateTime])

  useEffect(() => {
    localStorage.setItem('title', title)
  }, [title])
  useEffect(() => {
    localStorage.setItem('adminEmail', adminEmail)
  }, [adminEmail])

  useEffect(() => {
    const storedPeople = JSON.parse(localStorage.getItem('people'))
    if (storedPeople) {
      setPeople(storedPeople)
    }
  }, [])

  useEffect(() => {
    const savedPairs = localStorage.getItem('pairs')
    if (savedPairs) {
      setPairs(JSON.parse(savedPairs))
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('pairs', JSON.stringify(pairs))
  }, [pairs])

  const onDeleteParticipant = index => {
    const newPeople = [...people]
    newPeople.splice(index, 1)
    setPeople(newPeople)
  }

  const groupResults = Object.entries(
    pairs.reduce((groups, pair) => {
      const group = pair[1].group
      if (!groups[group]) {
        groups[group] = []
      }
      groups[group].push(pair)
      return groups
    }, {})
  )

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handleAddPerson = event => {
    event.preventDefault()
    if (people.some(person => person.email === email)) {
      alert('This email is already used. Please enter a different email.')
      return
    }
    setPeople(prevPeople => [...prevPeople, { name, email, group }])
    setName('')
    setEmail('')
    setGroup('')
    nameInputRef.current.focus()
  }

  useEffect(() => {
    localStorage.setItem('description', description)
  }, [description])

  const handleGeneratePairs = useCallback(() => {
    let pairs = []
    // Group people by their group
    let groups = {}
    people.forEach(person => {
      if (!groups[person.group]) {
        groups[person.group] = []
      }
      groups[person.group].push(person)
    })

    // Function to shuffle an array
    const shuffle = array => {
      let currentIndex = array.length,
        temporaryValue,
        randomIndex

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1

        // And swap it with the current element.
        temporaryValue = array[currentIndex]
        array[currentIndex] = array[randomIndex]
        array[randomIndex] = temporaryValue
      }

      return array
    }

    // Generate pairs within each group
    for (let group in groups) {
      let groupPeople = shuffle(groups[group])

      for (let i = 0; i < groupPeople.length; i++) {
        const uuid = uuidv4()
        let nextIndex = (i + 1) % groupPeople.length // Circular pairing
        pairs.push([uuid, groupPeople[i], groupPeople[nextIndex]])
      }
    }

    setPairs(shuffle(pairs))
  }, [people]) // add dependencies if there are any
  useEffect(() => {
    localStorage.setItem('people', JSON.stringify(people))
    handleGeneratePairs()
  }, [people, handleGeneratePairs])

  const uniqueGroups = [...new Set(people.map(person => person.group))]

  return (
    <Grid container spacing={12}>
      <Grid item xs={12}>
        <h1>Shuffle Match</h1>
        <p>Surprise Awaits in Every Match</p>

        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ExpandIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Typography>
              <h2 style={{ margin: '0 1em' }}>Participants</h2>
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ margin: '30px' }}>
            <Grid container xs={12} spacing={6}>
              <Grid item xs={12} md={pairs.length > 0 ? 6 : 12}>
                <form onSubmit={handleAddPerson}>
                  <Stepper
                    activeStep={activeStep}
                    style={{ padding: '20px 0 40px' }}
                  >
                    {steps.map((label, index) => (
                      <Step
                        key={label}
                        onClick={() => setActiveStep(index)}
                        disabled={
                          activeStep === steps.length - 1 ||
                          !(
                            description.includes('{Participant A}') &&
                            description.includes('{Participant B}')
                          )
                        }
                        style={{ cursor: 'pointer' }}
                      >
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <Grid container spacing={8}>
                    {activeStep === 0 && (
                      <>
                        <Grid item xs={12}>
                          <Tooltip title='The title with which the message will arrive.'>
                            <TextField
                              label='Title'
                              variant='outlined'
                              fullWidth
                              value={title}
                              onChange={e => setTitle(e.target.value)}
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                          <Tooltip title='The body of the message we are going to send.'>
                            <TextField
                              label='Description'
                              multiline
                              rows={4}
                              variant='outlined'
                              fullWidth
                              value={description}
                              onChange={e => setDescription(e.target.value)}
                              helperText={
                                !description.includes('{Participant A}') ||
                                !description.includes('{Participant B}')
                                  ? 'Remember to add {Participant A} and {Participant B} so we can send the message. These values will be replaced by the names of the participants'
                                  : ''
                              }
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                          <Tooltip title='Leave us your email to attach it when sending the mails in case someone needs your help'>
                            <TextField
                              label='Your Email'
                              variant='outlined'
                              fullWidth
                              value={adminEmail}
                              onChange={e => setAdminEmail(e.target.value)}
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                          <Tooltip title='If there is limit date, add it here.'>
                            <TextField
                              label='Event date'
                              type='datetime-local'
                              value={dateTime}
                              fullWidth
                              onChange={handleDateTimeChange}
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </Tooltip>

                          <Tooltip title='We notify you 3 days before the event if there are participants who have not seen their results.'>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={adminReminder}
                                  onChange={event =>
                                    setAdminReminder(event.target.checked)
                                  }
                                />
                              }
                              label='Send a reminder to the administrator'
                            />
                          </Tooltip>
                          <Tooltip title='We notify participants 3 days before the event if they have not seen their results.'>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={participantReminder}
                                  onChange={event =>
                                    setParticipantReminder(event.target.checked)
                                  }
                                />
                              }
                              label='Send a reminder to the participants'
                            />
                          </Tooltip>
                        </Grid>
                      </>
                    )}
                    {activeStep === 1 && (
                      <>
                        <Grid item xs={12}>
                          <Tooltip title="Enter the participant's name">
                            <TextField
                              inputRef={nameInputRef}
                              value={name}
                              onChange={e => setName(e.target.value)}
                              label='Name'
                              required
                              fullWidth
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                          <Tooltip title='Enter an email to send them the result'>
                            <TextField
                              value={email}
                              onChange={e => setEmail(e.target.value)}
                              label='Email'
                              required
                              fullWidth
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                          <Tooltip title='You can use this field to group participants. A separate draw is done for each group.'>
                            <Autocomplete
                              disablePortal
                              id='group'
                              fullWidth
                              freeSolo
                              options={uniqueGroups}
                              onInputChange={(e, newValue) =>
                                setGroup(newValue)
                              }
                              onChange={(e, newValue) => setGroup(newValue)}
                              value={group}
                              renderInput={params => (
                                <TextField {...params} label='Grup' />
                              )}
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item container xs={12} spacing={10}>
                          <Grid item xs={12}>
                            <Button
                              fullWidth
                              type='submit'
                              variant='contained'
                              color='primary'
                            >
                              Add participant
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Grid item>
                      <Button
                        disabled={activeStep === 0}
                        onClick={() =>
                          setActiveStep(prevActiveStep => prevActiveStep - 1)
                        }
                      >
                        Back
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant='contained'
                        color='primary'
                        disabled={
                          activeStep === steps.length - 1 ||
                          !(
                            description.includes('{Participant A}') &&
                            description.includes('{Participant B}')
                          )
                        }
                        onClick={() => {
                          setActiveStep(prevActiveStep => prevActiveStep + 1)
                        }}
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              {pairs.length > 0 && (
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ margin: '80px 0 0 0' }}
                  spacing={10}
                >
                  <TableContainer>
                    <ParticipantsTable
                      people={people}
                      onDeleteParticipant={onDeleteParticipant}
                    />
                  </TableContainer>
                  <Grid style={{ marginTop: '30px' }}>
                    <Button
                      fullWidth
                      variant='contained'
                      color='primary'
                      onClick={() => handleChange('panel2')(null, true)}
                    >
                      Show results
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={<ExpandIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <Typography>
              <h2 style={{ margin: '0 1em' }}>Results</h2>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {pairs.length > 0 && (
              <Grid item md={12} style={{ margin: '30px' }} spacing={10}>
                <TableContainer>
                  <ResultsTable
                    pairs={pairs}
                    showPairs={showPairs}
                    groupResults={groupResults}
                  />
                </TableContainer>
                <Grid container spacing={2} style={{ marginTop: '30px' }}>
                  <Grid item xs={6}>
                    <Button
                      variant='contained'
                      color='primary'
                      fullWidth
                      onClick={() => setShowPairs(!showPairs)}
                    >
                      {showPairs ? 'Hide' : 'Show'} results
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      onClick={handleGeneratePairs}
                      variant='contained'
                      color='secondary'
                    >
                      Redo
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </Grid>
  )
}

export default Home
