const placeholderNames = [
    'Pepe',
    'Juan',
    'Maria',
    'Jose',
    'Pedro',
    'Lucia',
    'Carlos',
    'Ana',
    'Luis',
    'Sofia',
    'Miguel',
    'Patricia',
    'Emilia',
    'Felipe',
    'Bautista',
    'Martina',
    'Agustina',
    'Ignacio',
    'Santiago',
    'Camila',
    'Tomas'
  ]

  export default placeholderNames