import React, { useState } from 'react'
import {
  Button,
  TableContainer,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'

import Footer from '../components/Footer'
import ResultsTable from '../components/ResultsTable'

function Home () {
  const [showPairs, setShowPairs] = useState(false)
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Grid container spacing={12}>
      <Grid item xs={12}>
        <h1>Shuffle Match</h1>
        <p>Surprise Awaits in Every Match</p>

        <Grid item md={12} style={{ margin: '30px' }} spacing={10}>
          <TableContainer>
            <ResultsTable
              pairs={[]}
              showPairs={showPairs}
              groupResults={[]}
              handleClickOpen={handleClickOpen}
            />
          </TableContainer>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={() => setShowPairs(!showPairs)}
        >
          {showPairs ? 'Ocultar' : 'Mostrar'} resultados
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          sx={{
            backgroundColor: 'green',
            padding: '20px',
            '&:hover': { backgroundColor: 'darkgreen' }
          }}
          fullWidth
          variant='contained'
          color='primary'
          onClick={() => setShowPairs(!showPairs)}
        >
          Notificar a los aprticpantes
        </Button>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Puede ser mas fácil! Mandá todo junto!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Podes mandar una notificaion a todos con un solo clic!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant='contained' color='primary'>
            Nah, mejor no
          </Button>
          <Button onClick={handleClose} variant='contained' color='primary'>
            Mandá todas ya por $1!
          </Button>
        </DialogActions>
      </Dialog>
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </Grid>
  )
}

export default Home
