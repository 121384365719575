import { Typography } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import Footer from "../components/Footer";

function Pair () {
  const { id } = useParams()
  const pairs = JSON.parse(localStorage.getItem('pairs'))
  const pair = pairs.find(i => i[0] === id)
  const title = localStorage
    .getItem('title')
    .replace('{amigo}', pair[1].name)
    .replace('{destinatario}', pair[2].name)
  const description = localStorage
    .getItem('description')
    .replace('{amigo}', pair[1].name)
    .replace('{destinatario}', pair[2].name)
  return (
    <div>
      <Typography>
        <h1>{title}</h1>
      </Typography>
      <Typography style={{ padding: '10px', whiteSpace: 'pre-line' }}>
        {description}
      </Typography>
      <Footer />
    </div>
  )
}

export default Pair
